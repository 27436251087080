<script setup lang="ts">
const {locale, locales} = useI18n();

const switchLocalePath = useSwitchLocalePath();

function updateLocale(selectedLocale: string) {
  navigateTo(switchLocalePath(selectedLocale), {
    replace: true,
  });
}
</script>

<template>
  <div class="w-10">
    <v-select
        variant="outlined"
        density="compact"
        :model-value="locale"
        @update:model-value="updateLocale"
        :items="locales"
        item-value="code"
        hide-details
        bg-color="surface"
    >
      <!-- TODO Add declaration file for flag property of country -->
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :title="''" density="compact">
          <nuxt-img
              :src="`/flags/4x3/${item.raw?.code?.toLowerCase()}.svg`"
              height="20px"
              class="mr-2"
          ></nuxt-img>
          {{ item.raw.name ?? item.raw }}
        </v-list-item>
      </template>
      <template v-slot:selection="{ item }" class="">
        <nuxt-img
            :src="`/flags/4x3/${item.raw?.code?.toLowerCase()}.svg`"
            height="20px"
            class=""
        ></nuxt-img>
        <div class="d-md-block ml-3">
          {{ item.raw.name ?? item.raw }}
        </div>
      </template>
    </v-select>
  </div>
</template>

<style scoped></style>
