<script lang="ts" setup>

import {useProductStore} from "~/stores/product";
import {useOrderStore} from "~/stores/order";
import {useShopStore} from "~/stores/shop";

const productStore = useProductStore();
const {productError} = storeToRefs(productStore);

const orderStore = useOrderStore();
const {placingOrderError} = storeToRefs(orderStore);

const shopStore = useShopStore();
const {qrDialog} = storeToRefs(shopStore);

const error = ref(false);

const isError = computed(() => productError.value || placingOrderError.value);

watch(isError, (newValue) => {
  console.log(newValue);
  if (newValue) {
    error.value = true;
    qrDialog.value = false;
  } else {
    error.value = false;
    qrDialog.value = true;
  }
});

function refresh() {
  location.reload();
}

</script>
<template>
  <v-dialog
      v-model="error"
      max-width="400"
      class="error-bar"
      persistent
  >
    <v-card class="pa-4 text-center justify-center align-center">
      Er ging iets mis!
      <v-btn
          class="mt-3 bg-primary"
          @click="refresh"
      >
        Refresh
      </v-btn>
    </v-card>
  </v-dialog>
</template>
<style>
.error-bar {
  z-index: 99999;
}
</style>