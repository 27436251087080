<script setup>
import {useDisplay} from "vuetify";

const fixed = ref(false);

const productStore = useProductStore();
const {categories} = storeToRefs(productStore);

const orderStore = useOrderStore();

const scrollToElement = (target_id) => {
  const el = document.getElementById("category_" + target_id);
  el.style.scrollMargin = '70px';
  if (el) {
    el.scrollIntoView({behavior: "smooth"});
  }
}

const {mdAndDown} = useDisplay()

</script>
<template>
  <div class="side-menu text-center" v-if="mdAndDown">
    <span
        v-for="(item, i) in categories"
        @click="scrollToElement(item.id)"
        class="menu-item text-body-2 text-decoration-underline">{{ item.name }} </span>
  </div>
</template>

<style>
a {
  text-decoration: none;
}

.side-menu {
  background-color: #e2e2e2;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  height: 50px;
}

.menu-item {
  height: 40px;
  display: inline-block;
  padding: 5px;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 10px;
}
</style>

